import React, { useEffect, useRef, useState } from "react";

interface OverlayInfoProps {
  text: string;
  moving?: boolean;
}

const MOVE_INTERVAL = 10; // seconds

export const OverlayInfo: React.FC<OverlayInfoProps> = ({ text, moving }) => {
  const [top, setTop] = useState(50);
  const [left, setLeft] = useState(50);
  const infoRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!moving) return;
    const moveInfo = () => {
      setTop(Math.round(Math.random() * 60) + 20);
      setLeft(Math.round(Math.random() * 60) + 20);
    };
    const intervalHandle = window.setInterval(moveInfo, MOVE_INTERVAL * 1000);
    return (): void => {
      window.clearInterval(intervalHandle);
    };
  }, [moving]);

  return (
    <div
      ref={infoRef}
      style={{
        position: "absolute",
        top: `${top}%`,
        left: `${left}%`,
        marginTop: "-50px",
        marginLeft: "-200px",
        width: "400px",
        height: "150px",
        fontSize: "24px",
        background: "rgba(0, 0, 0, 0.5)",
        borderRadius: "8px",
        textAlign: "center",
      }}
    >
      <p style={{ color: "white" }}>{text}</p>
    </div>
  );
};
