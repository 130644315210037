import React, { useEffect, useState } from "react";
import { registerScreen, initSSE } from "./api";
import { Screen } from "../../common/types";
import { PlaylistPlayer } from "./Playlist";
import { ScreenContainer } from "./ScreenContainer";
import { isNewScreen } from "./utils";

const localStorageKey = "screen";

interface PlayerOpts {
  platform?: string;
}
export interface NewScreen {
  platform?: string;
}

function getScreenInfo(opts: PlayerOpts): Screen | NewScreen {
  const newScreen = {
    platform: opts.platform || "web",
  };

  try {
    const screenStr = localStorage.getItem(localStorageKey);
    return screenStr ? JSON.parse(screenStr) : newScreen;
  } catch (e) {
    console.log("Failed to get screen info from local storage", e);
  }
  return newScreen;
}

async function register(_screen: Screen | NewScreen) {
  const screen = await registerScreen(_screen).catch((e) => {
    if (!isNewScreen(_screen) && e.statusCode === 404) {
      // conveniently create a new screen in case the old one was deleted
      console.log("Screen not found, creating new one");
      const { platform } = _screen;
      return registerScreen({
        platform,
      });
    }
  });
  localStorage.setItem(localStorageKey, JSON.stringify(screen));
  return screen;
}

export const Player = (opts: PlayerOpts) => {
  const [paircode, setPaircode] = useState("");
  const [screen, setScreen] = useState<Screen>();

  useEffect(() => {
    async function init() {
      const _screen = getScreenInfo(opts);
      const screen = await register(_screen);
      setScreen(screen);
      setPaircode(screen.paircode);
      initSSE(screen.id);
    }

    init();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  if (!screen) return null;

  if (paircode) {
    return (
      <ScreenContainer screen={screen}>
        <div
          style={{
            color: "gray",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <div style={{ fontSize: "32px", margin: "16px" }}>
            Pair your screen using the following code:
            <p style={{ fontSize: "96px", textAlign: "center", margin: "8px" }}>
              {paircode}
            </p>
          </div>
        </div>
      </ScreenContainer>
    );
  }

  if (!screen?.playlist) {
    return (
      <ScreenContainer screen={screen}>
        <div
          style={{
            color: "gray",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <div style={{ fontSize: "48px", margin: "16px" }}>
            No playlist casted to this screen yet!
          </div>
        </div>
      </ScreenContainer>
    );
  }

  return (
    <ScreenContainer screen={screen}>
      <PlaylistPlayer playlistId={screen?.playlist.id} />
    </ScreenContainer>
  );
};
