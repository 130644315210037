import React, { useEffect, useRef } from "react";
import { Content, MediaContent } from "../../common/types";
import "./Player.css";
import { YoutubePlayer } from "./YoutubePlayer";

// Cloudflare Stream player API
// https://developers.cloudflare.com/stream/viewing-videos/using-the-stream-player/using-the-player-api/
declare var Stream: any;

interface ContentViewerProps {
  content: Content;
  preload?: boolean;
}

function isCloudflareVideo(item: MediaContent): boolean {
  return item.storageType === "cloudflare";
}

interface VideoPlayerProps {
  content: MediaContent;
  preload: boolean;
}

const CloudflareVideoPlayer = ({ content, preload }: VideoPlayerProps) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const player = Stream(iframeRef.current);
    player.muted = preload ? true : false; // TODO: make muted configurable
    player.controls = false;
    player.loop = true;
    player.addEventListener("play", () => {
      console.log("cloudflare video playing!");
    });

    player.play().catch(() => {
      console.log("video playback failed, muting to try again");
      player.muted = true;
      player.play();
    });
    return () => {
      player.pause();
    };
  }, [content, preload]);

  return (
    <iframe
      ref={iframeRef}
      title={content.name}
      src={content.url}
      style={{ border: "none" }}
      allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
    ></iframe>
  );
};

const VideoPlayer = ({ content, preload }: VideoPlayerProps) => {
  return isCloudflareVideo(content) ? (
    <CloudflareVideoPlayer content={content} preload={preload} />
  ) : (
    <video style={{ width: "100%" }} autoPlay loop muted src={content.url} />
  );
};

export const ContentViewer = ({
  content,
  preload = false,
}: ContentViewerProps) => {
  if (content.type === "image") {
    return <img src={content.url} alt={content.name} />;
  } else if (content.type === "link") {
    return (
      <iframe
        src={content.url}
        title={content.name}
        allow="autoplay; encrypted-media; fullscreen;"
      />
    );
  } else if (content.type === "youtube") {
    return (
      <YoutubePlayer
        videoId={content.publicId}
        list={content.list}
        muted={preload === true ? true : content.muted}
        autoplay={true}
        controls={true}
        loop={true}
      />
    );
  }

  return <VideoPlayer content={content} preload={preload} />;
};
