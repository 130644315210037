import React from "react";
import { Center } from "./Center";
import "./Loading.css";

export const Loading = () => <div className="loading"></div>;

export const PageLoading = () => (
  <Center>
    <Loading />
  </Center>
);
