import React, { useEffect, useState } from "react";
import useSWR from "swr";
import { resolveUrl } from "./api";
import { Playlist } from "../../common/types";
import { calculateTurn } from "./utils";
import { getLogger } from "./logger";
import "./Player.css";
import { ContentViewer } from "./ContentViewer";
import { PageLoading } from "./Loading";
import { OverlayInfo } from "./OverlayInfo";
import { isDebugEnabled } from "./App";

const log = getLogger("playlist");

interface PlaylistPlayerProps {
  playlistId: string;
  isPreview?: boolean;
}
export const PlaylistPlayer = ({
  playlistId,
  isPreview,
}: PlaylistPlayerProps) => {
  const { data: playlist, error } = useSWR<Playlist>(
    resolveUrl(`playlist/${playlistId}`, isPreview ? { preview: true } : {})
  );

  if (error) {
    return <OverlayInfo text={error.message || "Error loading playlist"} />;
  }

  if (!playlist) return <PageLoading />;

  log("Playlist:", playlist);
  return (
    <>
      <PlaylistViewer playlist={playlist} />
      {isPreview && <OverlayInfo text={"Playlist preview"} moving={true} />}
    </>
  );
};

const startDate = Date.now();

interface PlaylistViewerProps {
  playlist: Playlist;
}
const PlaylistViewer = ({ playlist }: PlaylistViewerProps) => {
  const [itemIndex, setItemIndex] = useState(0);
  const [preloadItemIndex, setPreloadItemIndex] = useState<number | undefined>(
    undefined
  );
  const [timeLeft, setTimeLeft] = useState(0);

  // const [previewItemIndex, setPreviewItemIndex] = useState(0);
  // filter items without any content
  const items = playlist.items.filter((item) => item.content);

  useEffect(() => {
    const handle = setInterval(() => {
      const secondsPassed = (Date.now() - startDate) / 1000;
      const durations = items.map((item) => item.duration);
      const { turn: newItemIndex, timeLeft } = calculateTurn(
        secondsPassed,
        durations
      );
      setTimeLeft(timeLeft);

      if (newItemIndex !== itemIndex) {
        log(`Will play item for ${timeLeft} seconds`, newItemIndex, {
          name: items[newItemIndex]?.content.name,
          id: items[newItemIndex]?.content.id,
          secondsPassed,
          durations,
        });
        setPreloadItemIndex(undefined);
        setItemIndex(newItemIndex);
      }
    }, 1000);

    log(`Playing item ${itemIndex} for ${timeLeft}`);

    return () => clearInterval(handle);
  }, [itemIndex, items, timeLeft]);

  useEffect(() => {
    if (timeLeft && timeLeft < 10) {
      const newPreloadItemIndex = (itemIndex + 1) % items.length;
      if (newPreloadItemIndex !== preloadItemIndex) {
        log("Set preloading item", newPreloadItemIndex);
        setPreloadItemIndex(newPreloadItemIndex);
      }
    }
  }, [itemIndex, items.length, timeLeft, preloadItemIndex]);

  if (!items.length)
    return (
      <div
        style={{
          color: "gray",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <div style={{ fontSize: "48px", margin: "16px" }}>
          Assigned playlist is empty!
        </div>
      </div>
    );

  const currentItem = items[itemIndex];
  // This is needed in case some items are deleted from the playlist.
  // TODO: This could be replaced with a smarter schedule logic!
  if (!currentItem) return null;

  const { content } = currentItem;
  const previewContent =
    preloadItemIndex !== undefined ? items[preloadItemIndex].content : null;

  return (
    <>
      <div className="container">
        <ContentViewer content={content} />
      </div>
      {previewContent ? (
        <div
          className={[
            "preview-container",
            isDebugEnabled() ? "preview-debug" : "",
          ].join(" ")}
        >
          <ContentViewer content={previewContent} preload={true} />
        </div>
      ) : null}
    </>
  );
};
