import React from "react";
import { Player } from "./Player";
import { SWRConfig } from "swr";
import { fetcher } from "./api";
import { PlaylistPlayer } from "./Playlist";
import { reloadAppDaily } from "./utils";
import "./App.css";
import { ScreenContainer } from "./ScreenContainer";

import { getLogger } from "./logger";
const log = getLogger("app");

const url = new URL(window.location.href);
const previewPlaylistId = url.searchParams.get("playlistId");
const platform = url.searchParams.get("platform") || undefined;
const previewScreen = {
  orientation: url.searchParams.get("orientation") || "0",
};
const isPreview = !!previewPlaylistId;

const debug =
  url.searchParams.has("debug") && url.searchParams.get("debug") !== "0";

if (debug) {
  log("Debug is enabled!");
}
export const isDebugEnabled = () => debug;

reloadAppDaily();

function App() {
  return (
    <SWRConfig
      value={{
        fetcher,
      }}
    >
      {isPreview ? (
        <ScreenContainer screen={previewScreen}>
          <PlaylistPlayer
            playlistId={previewPlaylistId}
            isPreview={isPreview}
          />
        </ScreenContainer>
      ) : (
        <Player platform={platform} />
      )}
    </SWRConfig>
  );
}

export default App;
