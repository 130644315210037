import { useEffect, useLayoutEffect, useRef } from "react";
import { NewScreen } from "./Player";
import { Screen } from "../../common/types";

export function useTimeout(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the timeout.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return;
    }

    const id = setTimeout(() => savedCallback.current(), delay);

    return () => clearTimeout(id);
  }, [delay]);
}

export function reload() {
  window.location.reload();
}

export function sum(values: number[]) {
  return values.reduce((a, b) => a + b, 0);
}

export function calculateTurn(
  totalTimePassed: number,
  durations: number[]
): { turn: number; timeLeft: number } {
  if (durations.length === 0) return { turn: 0, timeLeft: 0 };

  const total = sum(durations);
  let interval = totalTimePassed % total;
  for (let i = 0; i < durations.length; i++) {
    if (interval < durations[i]) {
      return { turn: i, timeLeft: durations[i] - interval };
    }
    interval -= durations[i];
  }

  const lastIndex = durations.length - 1;
  return { turn: lastIndex, timeLeft: durations[lastIndex] - interval };
}

const now = new Date();

export function reloadAppDaily() {
  const midnight = new Date();
  midnight.setHours(24, 0, 0, 0);
  const timeLeft = midnight.getTime() - now.getTime();

  console.log(`Will reload app ${timeLeft / 3600_000} hours`);

  setTimeout(() => {
    reload();
  }, timeLeft);
}

export function isNewScreen(screen: Screen | NewScreen): screen is NewScreen {
  return (screen as Screen).id === undefined;
}
