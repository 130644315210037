import { NewScreen } from "./Player";
import { Screen } from "../../common/types";
import { isNewScreen, reload } from "./utils";

const API_BASE_URL = "/api/";

export class HTTPError extends Error {
  constructor(public statusCode: number, public message: string) {
    super(message || statusCode.toString());
  }
}

export const resolveUrl = (path: string, queryParams?: Record<string, any>) => {
  const base = `${API_BASE_URL}${path}`;
  const paramsStr = new URLSearchParams(queryParams).toString();
  return paramsStr ? `${base}?${paramsStr}` : base;
};

export const fetcher = (...args: Parameters<typeof fetch>) =>
  fetch(...args).then(async (res) => {
    const isJson = res.headers
      .get("content-type")
      ?.includes("application/json");
    const data = isJson ? await res.json() : null;

    // check for error response
    if (!res.ok) {
      // get error message from body or default to response status
      const error = new HTTPError(res.status, data?.message || res.statusText);
      throw error;
    }
    return data;
  });

export function postJsonData(url: string, body: Record<string, any>) {
  return fetcher(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function registerScreen(screen: Screen | NewScreen) {
  if (isNewScreen(screen)) {
    return postJsonData(resolveUrl("register-screen/"), screen);
  }

  const { id } = screen;
  return postJsonData(resolveUrl(`register-screen/${id}/`), {});
}

let evtSource: EventSource | undefined;
function refresh() {
  if (evtSource && evtSource.OPEN) {
    evtSource.close();
  }
  reload();
}

export function initSSE(id: string) {
  if (evtSource) return;

  const endpoint = resolveUrl(`screen/${id}/sse`);
  evtSource = new EventSource(endpoint);

  evtSource.addEventListener("cast", (event) => {
    console.log("Cast event", event);
    refresh();
  });

  evtSource.addEventListener("screen-update", (event) => {
    console.log("Screen update event", event);
    refresh();
  });

  evtSource.addEventListener("pair", (event) => {
    console.log("Pair event", event);
    refresh();
  });

  evtSource.addEventListener("remove", (event) => {
    console.log("Remove event", event);
    refresh();
  });

  evtSource.onerror = (err) => {
    console.error("EventSource failed:", err);
  };

  evtSource.onopen = () => {
    console.log("SSE: opened");
  };
}
