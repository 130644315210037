import React from "react";

interface YoutubePlayerProps {
  videoId: string;
  muted?: boolean;
  list?: boolean;
  autoplay?: boolean;
  controls?: boolean;
  loop?: boolean;
}

function buildURL(props: YoutubePlayerProps) {
  const mute = props.muted ? "1" : "0";
  const autoplay = props.autoplay === false ? "0" : "1"; //default true
  const controls = props.controls === true ? "1" : "0"; //default false
  const loop = props.loop === false ? "0" : "1"; //default true

  const prefix = props.list
    ? `videoseries?list=${props.videoId}&`
    : `${props.videoId}?playlist=${props.videoId}&`; // playlist=[videoId] is required for single video loop to work

  return `https://www.youtube-nocookie.com/embed/${prefix}autoplay=${autoplay}&mute=${mute}&controls=${controls}&loop=${loop}`;
}

export const YoutubePlayer = (props: YoutubePlayerProps) => {
  const src = buildURL(props);
  return (
    <iframe
      src={src}
      title="youtube"
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
    ></iframe>
  );
};
