import { Screen } from "../../common/types";

interface ScreenContainerProps {
  screen: Partial<Screen>;
}

export const ScreenContainer: React.FC<ScreenContainerProps> = ({
  children,
  screen,
}) => {
  const angle = screen.orientation || "0";

  let transform = "";
  let transformOrigin = "";
  let width = "100%";
  let height = "100%";

  if (angle === "90" || angle === "270") {
    const translate =
      angle === "90" ? "translateY(-100%)" : "translateX(-100%)";
    transform = `rotate(${angle}deg) ${translate}`;
    transformOrigin = "top left";
    width = `${window.innerHeight}px`;
    height = `${window.innerWidth}px`;
  } else {
    transform = `rotate(${angle}deg)`;
    transformOrigin = "center";
  }

  return (
    <div
      style={{
        transform,
        transformOrigin,
        width,
        height,
        position: "relative",
      }}
    >
      {children}
    </div>
  );
};
